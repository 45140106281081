import { BentoBoxHomepageProps } from '@ww-digital/web-palette-react/dist/components/BentoBoxHomepage/BentoBoxHomepage';
import { PrimaryCardProps } from '@ww-digital/web-palette-react/dist/components/BentoBoxHomepage/PrimaryCards/PrimaryCard';

export const headingSection: BentoBoxHomepageProps['headingSection'] = {
  heading: {
    text: 'Weight loss. Health gains.',
    typog: 'mastheadHeadline',
  },
  subHeading: {
    text: `Choose the path that <strong>fits you.</strong>`,
    typog: 'bodycopyExtraLarge',
  },
};

export const footerSection: BentoBoxHomepageProps['footerSection'] = {
  button: {
    children: 'Take our quiz',
    type: 'primary',
    size: 'medium',
    url: 'https://www.weightwatchers.com/us/personalassessment/?isSequence=true',
    accessibilityLabel: 'Take our quiz',
  },
  secondaryButton: {
    children: 'View pricing',
    type: 'secondary',
    size: 'medium',
    url: 'https://www.weightwatchers.com/us/plans',
    accessibilityLabel: 'View pricing',
  },
  headline: {
    text: "Not sure? Let's find the solution for you.",
    typog: 'bodycopy',
  },
  version: 'v1',
};

export const primaryCardOne: PrimaryCardProps = {
  backgroundColor: '#fbf2eb',
  heading: {
    text: 'Eat healthier with Points®',
    typog: 'bodycopyExtraLarge',
  },
  image: {
    mobile: {
      src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1727810994/visitor-site/dev/us/Bento%20Box/Points_800px.png',
      alt: 'Hand holding a phone while viewing WW app',
      loading: 'eager',
    },
    desktop: {
      src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1727810994/visitor-site/dev/us/Bento%20Box/Points_800px.png',
      alt: 'Hand holding a phone while viewing WW app',
      loading: 'eager',
    },
  },
  link: {
    url: 'https://www.weightwatchers.com/us/how-it-works/points-program',
  },
};

export const primaryCardTwo: PrimaryCardProps = {
  heading: {
    text: '<span class="typog--accent-blue"><strong>NEW! </strong></span>Access weight-loss meds',
    typog: 'bodycopyExtraLarge',
  },
  image: {
    mobile: {
      src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1727810994/visitor-site/dev/us/Bento%20Box/Clinic_800px.png',
      alt: 'Three injection pens floating',
      loading: 'eager',
    },
    desktop: {
      src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1727810994/visitor-site/dev/us/Bento%20Box/Clinic_800px.png',
      alt: 'Three injection pens floating',
      loading: 'eager',
    },
  },
  link: {
    url: 'https://www.weightwatchers.com/us/clinic',
  },
};

export const primaryCardThree: PrimaryCardProps = {
  heading: {
    text: 'Join a Workshop',
    typog: 'bodycopyExtraLarge',
  },
  image: {
    mobile: {
      src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1727812031/visitor-site/dev/us/Bento%20Box/Workshop_800px_v2.png',
      alt: 'Two women chatting while holding phones',
      loading: 'eager',
    },
    desktop: {
      src: 'https://cmx.weightwatchers.com/assets-proxy/weight-watchers/image/upload/v1727812031/visitor-site/dev/us/Bento%20Box/Workshop_800px_v2.png',
      alt: 'Two women chatting while holding phones',
      loading: 'eager',
    },
  },
  link: {
    url: 'https://www.weightwatchers.com/us/how-it-works/points-program/workshops',
  },
};

export const bentoBoxHomepageData: BentoBoxHomepageProps = {
  headingSection,
  footerSection,
  bentoBoxSection: {
    primaryCardOne,
    primaryCardTwo,
    primaryCardThree,
  },
  version: 'v1',
};
